import { Component, Host, h, Element } from '@stencil/core';

@Component({
  tag: 'x-file-carousel',
})
export class XFileCarousel {
  @Element() el: HTMLElement;

  viewer;

  private handleSelect = e => {
    const thumb = e.target.closest('x-file-preview');

    if (!thumb) {
      return;
    }

    this.el.querySelector('x-file-preview[selected]').removeAttribute('selected');
    this.viewer.setAttribute('src', thumb.getAttribute('download'));
    thumb.setAttribute('selected', '');
  };

  componentDidLoad() {
    this.viewer = this.el.querySelector('x-file-viewer');
    this.el.addEventListener('click', this.handleSelect);
  }

  render() {
    return (
      <Host>
        <slot></slot>
      </Host>
    );
  }
}
